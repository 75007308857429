import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

import "./index.css";

import App from "./App";

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const link = createHttpLink({
  uri: "https://desolate-tor-78037.herokuapp.com/server-3/dev",
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

console.log('-----------------------------');
console.log('domain:', config.domain);
console.log('clientId:', config.clientId);
console.log('redirect_uri:', window.location.origin);
console.log('history:', history);
console.log('-----------------------------');

ReactDOM.render(
  <Auth0Provider domain={config.domain} client_id={config.clientId} redirect_uri={window.location.origin} onRedirectCallback={onRedirectCallback}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
