import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLegend } from "victory";

import "./Leaderboard.css";

import LeaderboardRecordDrawer from "./LeaderboardRecordDrawer";

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerStatus: {},
      groupAnalyticStatus: {},
      chartLoadingHeight: 364,
      colors: this.shuffle(["#ff6347", "#ffa347", "#ffd147", "#ffff47", "#d1ff47", "#a3ff47", "#75ff47", "#47ff47", "#47ff75", "#47ffa3", "#47ffd1", "#47ffff", "#47d1ff", "#47a3ff", "#4775ff", "#4747ff", "#7547ff", "#a347ff", "#d147ff", "#ff47ff", "#ff47d1", "#ff47a3", "#ff4775", "#ff4747"]),
    };
  }

  componentWillUpdate() {
    if (document.getElementById("chartDiv")) {
      if (this.state.chartLoadingHeight !== document.getElementById("chartDiv").offsetHeight - 16) {
        this.setState({ chartLoadingHeight: document.getElementById("chartDiv").offsetHeight - 16 });
      }
    }
  }

  render() {
    let nameIdsFilter = { OR: [] };

    for (var nameId of Object.keys(this.state.groupAnalyticStatus)) {
      if (this.state.groupAnalyticStatus[nameId]) {
        nameIdsFilter.OR.push({ nameId: nameId });
      }
    }

    return (
      <div id="leaderboard" className="container">
        {/* {this.props.playMusic && <Sound url={cottonEyeJoe} playStatus={Sound.status.PLAYING} />} */}
        <div className="row">
          <div className="col">
            <h1 className="centered">Power Rankings of People We Need to Put Down</h1>
          </div>
        </div>
        {nameIdsFilter.OR.length > 0 && (
          <Query query={QUERY_2} variables={{ nameIdsFilter }}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col">
                        <p className="fetching" style={{ height: this.state.chartLoadingHeight }}>
                          Fetching from the Daddybase(TM)
                        </p>
                      </div>
                    </div>
                  </div>
                );
              if (error) {
                console.log("Error:", error);
                return <div>Error</div>;
              }
              const { leaderboardRecords } = data;
              let graphData = {};
              let i = 0;
              Object.keys(this.state.groupAnalyticStatus).forEach((nameId) => {
                if (this.state.groupAnalyticStatus[nameId]) {
                  graphData[nameId] = {};
                  graphData[nameId].data = [];
                  graphData[nameId].color = this.state.colors[i % this.state.colors.length];
                  leaderboardRecords.forEach((r) => {
                    if (nameId === r.nameId) {
                      graphData[nameId].data.push({ x: new Date(r.officialCreatedAt), y: r.globalRank });
                    }
                  });
                  i += 1;
                }
              });
              // for (var nameId of Object.keys(this.state.groupAnalyticStatus)) {
              //   if (this.state.groupAnalyticStatus[nameId]) {
              //     graphData[nameId] = {};
              //     graphData[nameId].data = [];
              //     graphData[nameId].color = this.state.colors[i % this.state.colors.length];
              //     leaderboardRecords.forEach((r) => {
              //       if (nameId && nameId === r.nameId) {
              //         graphData[nameId].data.push({ x: new Date(r.officialCreatedAt), y: r.globalRank });
              //       }
              //     });
              //     i += 1;
              //   }
              // }

              let graphLines = [];
              let legendData = [];
              Object.keys(this.state.groupAnalyticStatus).forEach((nameId) => {
                if (this.state.groupAnalyticStatus[nameId]) {
                  graphLines.push(
                    <VictoryLine
                      style={{
                        data: { stroke: graphData[nameId].color },
                      }}
                      data={graphData[nameId].data}
                    />
                  );
                  legendData.push({ name: nameId.length > 10 ? nameId.substring(0, 10) + "..." : nameId, symbol: { fill: graphData[nameId].color } });
                }
              });
              // for (var nameId of Object.keys(this.state.groupAnalyticStatus)) {
              //   if (this.state.groupAnalyticStatus[nameId]) {
              //     graphLines.push(
              //       <VictoryLine
              //         style={{
              //           data: { stroke: graphData[nameId].color },
              //         }}
              //         data={graphData[nameId].data}
              //       />
              //     );
              //     legendData.push({ name: nameId.length > 10 ? nameId.substring(0, 10) + "..." : nameId, symbol: { fill: graphData[nameId].color } });
              //   }
              // }

              return (
                <div id="chartDiv">
                  <VictoryChart width={400} height={250} scale={{ x: "time" }} domainPadding={5}>
                    <VictoryAxis crossAxis width={400} height={250} scale={"time"} standalone={false} tickCount={4} tickFormat={(t) => new Date(t).toLocaleDateString().replace("2019", "19").replace("2020", "20").replace("2021", "21").replace("2022", "22")} />
                    <VictoryAxis dependentAxis crossAxis width={400} height={250} standalone={false} invertAxis={true} tickFormat={(t) => Math.round(t * 10) / 10} />
                    {graphLines}
                  </VictoryChart>
                  <VictoryLegend
                    x={56}
                    y={0}
                    height={26 + 28 * Math.ceil(legendData.length / 3)} // 1=52, 2=80, 3=108
                    title="Legend"
                    centerTitle
                    orientation="horizontal"
                    itemsPerRow={3}
                    gutter={20}
                    style={{ border: { stroke: "black" }, title: { fontSize: 14 }, labels: { fontSize: 12 } }}
                    data={legendData}
                  />
                </div>
              );
            }}
          </Query>
        )}

        <Query query={QUERY}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className="fetching">Fetching from the Daddybase(TM)</p>
                    </div>
                  </div>
                </div>
              );
            if (error) {
              console.log("Error:", error);
              return <div>Error</div>;
            }
            const { leaderboardRecords } = data;

            var currentCategory;
            var firstOfItsCategory;
            const table = leaderboardRecords.map((record) => {
              firstOfItsCategory = currentCategory !== record.category;
              currentCategory = record.category;
              return (
                <div key={record.nameId}>
                  {firstOfItsCategory && (
                    <div className="row category-title">
                      <div className="col">
                        <h5 className="centered">{record.category}</h5>
                      </div>
                    </div>
                  )}
                  <div className={"row record " + (record.localRank % 2 === 1 ? "primary-row" : "secondary-row")}>
                    <div className="col col-1 col-sm-1">
                      <p className={"centered " + (this.state.groupAnalyticStatus[record.nameId] ? "font-weight-bold" : "font-weight-normal")}>{record.globalRank}</p>
                    </div>
                    <div className="col col-sm-7 col-md-9">
                      <p className={this.state.groupAnalyticStatus[record.nameId] ? "font-weight-bold" : "font-weight-normal"}>{record.name}</p>
                    </div>
                    <div className="col col-4 col-sm-3 col-md-2">
                      <div className="centered">
                        <button
                          className="custom-button btn btn-primary"
                          onClick={() => {
                            const { drawerStatus } = this.state;
                            drawerStatus[record.nameId] = !drawerStatus[record.nameId];
                            this.setState({ drawerStatus: drawerStatus });
                          }}
                        >
                          {this.state.drawerStatus[record.nameId] ? "\u25B2" : `\u25BC`}
                        </button>
                        <button
                          className="custom-button btn btn-primary ml-2"
                          onClick={() => {
                            const { groupAnalyticStatus } = this.state;
                            groupAnalyticStatus[record.nameId] = !groupAnalyticStatus[record.nameId];
                            this.setState({ groupAnalyticStatus: groupAnalyticStatus });
                          }}
                        >
                          {this.state.groupAnalyticStatus[record.nameId] ? "-" : `+`}
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.drawerStatus[record.nameId] && <LeaderboardRecordDrawer record={record} />}
                </div>
              );
            });

            return table;
          }}
        </Query>
      </div>
    );
  }

  shuffle(array) {
    var currentIndex = array.length;
    var temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}

export default Leaderboard;

const QUERY = gql`
  query getMostRecentLeaderboardRecords {
    leaderboardRecords(where: { mostRecentRecord: true }, orderBy: globalRank_ASC) {
      name
      nameId
      category
      categoryRank
      globalRank
      localRank
      mostRecentRecord
      globalPopulation
    }
  }
`;

const QUERY_2 = gql`
  query getAllLeaderboardRecordsForSomeBadGuys($nameIdsFilter: LeaderboardRecordWhereInput!) {
    leaderboardRecords(where: $nameIdsFilter, orderBy: officialCreatedAt_DESC) {
      name
      nameId
      category
      categoryRank
      globalRank
      localRank
      mostRecentRecord
      globalPopulation
      officialCreatedAt
    }
  }
`;
