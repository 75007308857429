import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

class MessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yourName: "",
      yourDadsName: "",
      message: "",
    };
  }

  validate() {
    const { yourName, yourDadsName, message } = this.state;
    if (yourName && yourDadsName && message) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { yourName, yourDadsName, message } = this.state;
    return (
      <div className="container">
        <div className="row">
          <input
            className="mb2 col mr-5 data-input"
            value={yourName}
            onChange={(e) => this.setState({ yourName: e.target.value })}
            type="text"
            placeholder="Your name"
            required
          />
          <input
            className="mb2 col mr-5 data-input"
            value={yourDadsName}
            onChange={(e) => this.setState({ yourDadsName: e.target.value })}
            type="text"
            placeholder="Your Dad's name"
            required
          />
          <textarea
            className="mb2 col mr-5 data-input"
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            placeholder="Your message for your Dad"
            required
          />
          <Mutation
            mutation={CREATE_MESSAGE_MUTATION}
            update={(cache, { data: { createMessageForYourDad } }) => {
              const { messageForYourDads } = cache.readQuery({
                query: this.props.parentQuery,
              });
              cache.writeQuery({
                query: this.props.parentQuery,
                data: {
                  messageForYourDads: [createMessageForYourDad].concat(
                    messageForYourDads
                  ),
                },
              });
            }}
          >
            {(createMessage) => (
              <button
                onClick={() => {
                  if (this.validate()) {
                    createMessage({
                      variables: {
                        yourName: this.state.yourName,
                        yourDadsName: this.state.yourDadsName,
                        message: this.state.message,
                        dateTime: new Date().toISOString(),
                      },
                    });
                    this.setState({
                      yourName: "",
                      yourDadsName: "",
                      message: "",
                    });
                  }
                }}
              >
                Submit
              </button>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

export default MessageForm;

const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessageMutation(
    $yourName: String!
    $yourDadsName: String!
    $message: String!
    $dateTime: DateTime!
  ) {
    createMessageForYourDad(
      data: {
        from: $yourName
        to: $yourDadsName
        message: $message
        originalCreatedAt: $dateTime
      }
    ) {
      id
      from
      to
      message
      originalCreatedAt
    }
  }
`;
