import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Sound from "react-sound";

import "./TalkToYourDad.css";
import MessageForm from "./MessageForm";

import generationK from "../assets/songs/generation-k.mp3";

class TalkToYourDad extends React.Component {
  render() {
    return (
      <div id="talk-to-your-dad">
        {this.props.playMusic && <Sound url={generationK} playStatus={Sound.status.PLAYING} />}
        <h1 className="centered mb-4">You should really talk to your dad</h1>
        <MessageForm parentQuery={GET_DAD_MESSAGES_QUERY} />
        <Query query={GET_DAD_MESSAGES_QUERY}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className="fetching">Fetching from the Daddybase(TM)</p>
                    </div>
                  </div>
                </div>
              );
            if (error) {
              console.log("Error:", error);
              return <div>Error</div>;
            }
            const dadTable = data.messageForYourDads.map((messageObject) => {
              var { from, to, originalCreatedAt, message, id } = messageObject;
              var timestamp = new Date(originalCreatedAt).toLocaleString();
              return (
                <div className="row card" key={id}>
                  <div className="col">
                    <h5>Dear {to},</h5>
                    <h5>{message}</h5>
                    <h5>Love, {from}</h5>
                    <p className="mb-1" style={{ fontSize: 12 }}>
                      {timestamp}
                    </p>
                  </div>
                </div>
              );
            });

            return <div className="container">{dadTable}</div>;
          }}
        </Query>
      </div>
    );
  }
}

export default TalkToYourDad;

const GET_DAD_MESSAGES_QUERY = gql`
  query GetDadMessagesQuery {
    messageForYourDads(orderBy: originalCreatedAt_DESC) {
      id
      from
      to
      originalCreatedAt
      message
    }
  }
`;
