import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { UseAuth0InComponent } from "./react-auth0-spa";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import "./App.css";

import Navigation from "./Navigation/Navigation";
import CustomCursor from "./CustomCursor/CustomCursor";
import EntryPopup from "./EntryPopup/EntryPopup";

// Disable react-sound console logs for entire project
/* global soundManager:false */
import "react-sound";
soundManager.setup({ debugMode: false });

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      pageAccess: false,
      appAllowMusic: false,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  grantPageAccess() {
    this.setState({ pageAccess: true });
    this.setState({ appAllowMusic: true });
  }

  render() {
    return (
      <div id="app">
        <UseAuth0InComponent>
          {(auth0) => {
            return !auth0.loading ? (
              auth0.user ? (
              <Query query={QUERY} variables={{ auth0id: auth0.user.sub}}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col">
                            <p className="fetching">Fetching from the Daddybase(TM)</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (error) {
                    console.log("Error:", error);
                    return <div>Error</div>;
                  }
                  const userData = data.users[0];
                  return (
                    <>
                      <EntryPopup onCloseFn={() => this.grantPageAccess()} />
                      <div style={this.state.pageAccess ? null : { filter: "blur(6px)" }}>
                        <Navigation appAllowMusic={this.state.appAllowMusic} auth0={auth0} userData={userData} />
                        {this.state.width > 500 && <CustomCursor />}
                      </div>
                    </>
                  );
                }}
              </Query>
              ) : (
                <>
                  <EntryPopup onCloseFn={() => this.grantPageAccess()} />
                  <div style={this.state.pageAccess ? null : { filter: "blur(6px)" }}>
                    <Navigation appAllowMusic={this.state.appAllowMusic} auth0={auth0} />
                    {this.state.width > 500 && <CustomCursor />}
                  </div>
                </>
              )
            ) : (
              <div>Authentication in progress...</div>
            );
          }}
        </UseAuth0InComponent>
      </div>
    );
  }
}

export default App;

const QUERY = gql`
  query getUserByAuth0($auth0id: String!) {
    users(where: { auth0id: $auth0id }) {
      id
      firstName
      lastName
      username
      email
      originalCreatedAt
    }
  }
`;
