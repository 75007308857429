import React from "react";
import ReactModal from "react-modal";

import "./EntryPopup.css";

class EntryPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: true,
    };

    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  handleClosePopup() {
    this.setState({ showPopup: false });
    this.props.onCloseFn();
  }

  render() {
    return (
      <div id="entryPopup">
        <ReactModal isOpen={this.state.showPopup} onRequestClose={this.handleClosePopup} className="Modal" shouldCloseOnOverlayClick={false} overlayClassName="Overlay" ariaHideApp={false}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1>I'm a</h1>
              </div>
            </div>
            <div className="row equal mt-5">
              <div className="col">
                <button className="btn custom-modal-button p-3" onClick={this.handleClosePopup}>
                  <h2>Good Guy</h2>
                </button>
              </div>
              <div className="col">
                <button className="btn custom-modal-button p-3" onClick={this.handleClosePopup}>
                  <h2>Bad Dad</h2>
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default EntryPopup;
