import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { VictoryChart, VictoryLine, VictoryAxis } from "victory";

import "./LeaderboardRecordDrawer.css";

class LeaderboardRecordDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "table",
      tableDesc: true,
      tableRank: true,
      tableCategory: true,
      tablePercentile: true,
      tableUpdated: true,
    };
  }

  render() {
    const { nameId, localRank } = this.props.record;
    return (
      <div id="leaderboardRecordDrawer" className={"row pt-3 " + (localRank % 2 === 1 ? "primary-drawer" : "secondary-drawer")}>
        <Query query={QUERY} variables={{ nameId }}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className="fetching">Fetching from the Daddybase(TM)</p>
                    </div>
                  </div>
                </div>
              );
            if (error) {
              console.log("Error:", error);
              return <div>Error</div>;
            }

            const graphData = data.leaderboardRecords.map((r) => {
              return { x: new Date(r.officialCreatedAt), y: r.globalRank };
            });

            var descriptionPresent = false;
            const recordTable = data.leaderboardRecords.map((r) => {
              var descriptionStartIndex = 0;
              var descriptionEndIndex = 0;
              if (r.name.search("[(]") !== -1) {
                descriptionPresent = true;
                descriptionStartIndex = r.name.search("[(]") + 1;
                descriptionEndIndex = r.name.search("[)]");
              }

              return (
                <div className="row table-row">
                  {this.state.tableDesc && descriptionPresent && (
                    <div className="col col-3 col-sm-3 col-md-4">
                      <p className="centered small-font">{r.name.substring(descriptionStartIndex, descriptionEndIndex)}</p>
                    </div>
                  )}
                  {this.state.tableRank && (
                    <div className="col col-2 col-sm-2 col-md-1">
                      <p className="centered">
                        {r.globalRank}-{r.localRank}
                      </p>
                    </div>
                  )}
                  {this.state.tableCategory && (
                    <div className="col col-3 col-sm-3 col-md-4">
                      <p className="centered small-font">
                        {r.category} ({r.categoryRank})
                      </p>
                    </div>
                  )}
                  {this.state.tablePercentile && (
                    <div className="col col-2 col-sm-2 col-md-1">
                      <p className="centered">{Math.round(1000 * (1 - r.globalRank / r.globalPopulation)) / 10}</p>
                    </div>
                  )}
                  {this.state.tableUpdated && (
                    <div className="col col-2 col-sm-2 col-md-2">
                      <p className="centered small-font">{new Date(r.officialCreatedAt).toLocaleString().split(",")[0].replace("2019", "19").replace("2020", "20").replace("2021", "21").replace("2022", "22")}</p>
                    </div>
                  )}
                </div>
              );
            });
            return (
              <div className="col">
                {this.state.view === "table" && (
                  <div>
                    <div className="row">
                      <div className="col col-2 col-sm-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.setState({ view: "graph" });
                          }}
                        >
                          Graph
                        </button>
                      </div>
                      <div className="col col-10 col-sm-10">
                        <div className="row justify-content-end table-control-row">
                          {descriptionPresent && (
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                this.setState({ tableDesc: !this.state.tableDesc });
                              }}
                            >
                              Desc
                            </button>
                          )}
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ tableRank: !this.state.tableRank });
                            }}
                          >
                            Rank
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ tableCategory: !this.state.tableCategory });
                            }}
                          >
                            Category
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ tablePercentile: !this.state.tablePercentile });
                            }}
                          >
                            Percentile
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ tableUpdated: !this.state.tableUpdated });
                            }}
                          >
                            Updated
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row table-row justify-content-around">
                      {this.state.tableDesc && descriptionPresent && (
                        <div className="col col-3 col-sm-3 col-md-4">
                          <h6 className="centered">Desc</h6>
                        </div>
                      )}
                      {this.state.tableRank && (
                        <div className="col col-2 col-sm-2 col-md-1">
                          <h6 className="centered">Rank</h6>
                        </div>
                      )}
                      {this.state.tableCategory && (
                        <div className="col col-3 col-sm-3 col-md-4">
                          <h6 className="centered">Category</h6>
                        </div>
                      )}
                      {this.state.tablePercentile && (
                        <div className="col col-2 col-sm-2 col-md-1">
                          <h6 className="centered">%</h6>
                        </div>
                      )}
                      {this.state.tableUpdated && (
                        <div className="col col-2 col-sm-2 col-md-2">
                          <h6 className="centered">Updated</h6>
                        </div>
                      )}
                    </div>
                    {recordTable}
                  </div>
                )}
                {this.state.view === "graph" && (
                  <div>
                    <div className="row graph-control-row">
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          this.setState({ view: "table" });
                        }}
                      >
                        Table
                      </button>
                    </div>
                    <div className="row graph-row">
                      <VictoryChart width={400} height={250} scale={{ x: "time" }} domainPadding={5}>
                        <VictoryAxis crossAxis width={400} height={250} scale={"time"} standalone={false} tickCount={4} tickFormat={(t) => new Date(t).toLocaleDateString().replace("2019", "19").replace("2020", "20").replace("2021", "21").replace("2022", "22")} />
                        <VictoryAxis dependentAxis crossAxis width={400} height={250} standalone={false} invertAxis={true} tickFormat={(t) => Math.round(t * 10) / 10} />
                        <VictoryLine
                          style={{
                            data: { stroke: "tomato" },
                          }}
                          data={graphData}
                        />
                      </VictoryChart>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default LeaderboardRecordDrawer;

const QUERY = gql`
  query getLeaderboardRecordsOfOneGuy($nameId: String!) {
    leaderboardRecords(where: { nameId: $nameId }, orderBy: officialCreatedAt_DESC) {
      id
      name
      nameId
      category
      categoryRank
      globalRank
      localRank
      mostRecentRecord
      officialCreatedAt
      globalPopulation
    }
  }
`;
