import React from "react";
import Sound from "react-sound";
import { Fade } from "react-slideshow-image";

import "./Home2.css";

import fatherFigure from "../assets/songs/father-figure-compressed.mp3";
import soundOfSilence from "../assets/songs/sound-of-silence.mp3";
import dadGif from "../assets/images/dad.gif";

class Home2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playMusicForever: false,
      legacyMode: false,
    };
  }

  render() {
    if (!this.state.legacyMode) {
      const slideshowImages = this.shuffle(fadeImages)
        .slice(0, 69)
        .map((image) => {
          return (
            <div className="each-fade" key={image}>
              <div className="image-container">
                <img src={image} alt="slideshow" />
              </div>
            </div>
          );
        });

      return (
        <div id="home2">
          {this.props.playMusic && !this.state.playMusicForever && (
            <Sound
              url={fatherFigure}
              playStatus={Sound.status.PLAYING}
              position={28000}
              onFinishedPlaying={() => {
                this.setState({ playMusicForever: true });
              }}
            />
          )}
          {this.props.playMusic && this.state.playMusicForever && <Sound url={fatherFigure} playStatus={Sound.status.PLAYING} loop={true} />}
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="centered mt-3 mb-2">
                  {" "}
                  Have you seen my dad??
                  <span
                    onClick={() => {
                      this.setState({ legacyMode: true });
                    }}
                  >
                    ?
                  </span>
                  ?
                </h1>
                <div className="slide-container">
                  <Fade id="fade" duration={5000} transitionDuration={400} infinite={true} indicators={true}>
                    {slideshowImages}
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="home1">
          {this.props.playMusic && <Sound url={soundOfSilence} playStatus={Sound.status.PLAYING} />}
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="centered mt-3 mb-2">
                  {" "}
                  Have you seen my dad??
                  <span
                    onClick={() => {
                      this.setState({ legacyMode: false });
                    }}
                  >
                    ?
                  </span>
                  ?
                </h1>
                <img className="centered" src={dadGif} alt="Dad?!??! DAD!!!!" />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
}

export default Home2;

const fadeImages = [
  "https://drive.google.com/thumbnail?id=1XYRqpB_8wjcfz8J5bitcElowxItJg1qk&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1i72Wgn98WwVCFBdF1K4WTsKSnbJPPPCb&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Ub5jFaRFIOniy0fw0C09dIaUTiRlOWp5&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=15uw3gpTIezE-LyxPtpeLTsPulZ0qDZ_Q&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1__rgruC6klODEJspAwdYLrK0D7LKroc-&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1FunO9M1aYbVy5bgt-fygYE7mlJNaby1_&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1PikGMg3V-dzyReiogYjEVLzIBYEss9No&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Bm_L7eAhxRYd7Tq-uqkKbj1u4E5Em30v&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1TPFwmeZ7VB9WOYyTgqtVUuMiWO1WskBJ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1863KWys-bkniK0WTJbbrDGA3EFFzF2Lq&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1v-B1hyZPF9kxmo_Vvnzx_UAygtGlIwGK&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=18d8rwaeqVEu54pYBqDOOZsCH8GrWqXia&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1QUNyar72M4lmu0VSX3p06G0Yx87wv8Yu&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1bs6lVwGHffzVSflZRxhGwLaVuGk22U-O&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1O_9a64d-VIloNajVAMC0mOQ-x-UNHpP9&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1BjyfbUFySe2QESlaubgFpNPv8izDfEac&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1IAXIs0Tm1DUeJTEO22d4QAai3MtNL3-z&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wY31grSzfMuvJOVazeXiAgsxu3MuBo4x&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1MgzypjJAx59YXUnyNYfn47pyBX1kIUoM&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1CJVRXRskgPXPAJde894njf6xG6pOdxJv&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Rs4J3DXgXSk320nG44FMHaJgMjfPTkHo&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1di9TaS0UsS32-uCy_7H3FBp8_5F7l6gF&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1nbd1Ef2SfB14ljBO5cYfq0OUIUXdNMQ7&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1qZzFVfWOck0E7D0f08l1rvCZsxRA_WLw&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1uLec8ePcrlSQU4vQH7sv8RNBjPeGrWpa&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1L8XgiFsPt3P2XU6Icopu28ZlXljat3yt&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1vy7gdF6spKkCox7RT8Bfv131bO8ArDXE&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1hJ7dfFWFAMBubedVqo7E2QKi_TFhv83T&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=13j-botSx7ILyvkIJOp_A0PNmg9yMENQk&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1GupiKGDk8qNLC48bNNTckmPPWvRvi_4i&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1-t2kcuYWheWz1lf1VUDWMV3pwGrG-jBw&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=18P-Wxcboqpq0o1tU69kptuyw9IpHaNZT&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1RLs_fj6wjtC1co5i1DnxFlV0IGISbHVs&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1U328AVHlUY95ZQC7pp3q7PqduA3a7cUi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1XKp-yD-mmzoyqqEXAuFPLuqTLjSXbgog&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1AbIjfD6xoPCRg3VIHEIc3ILyB5nPbCD8&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Hh4gg6pw-vfHGtJeryf0Jy8ur6IUckLz&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1PSYAzxgVPOfYTAAIfU0w8VgMggh01IwD&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wYWcKOGf410On3ZX6PA11JS9UeKnvmUC&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1xLh7C0-eTcR95LSZpXjYwpm0sxQxB8vN&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1lK08mqdw6GfXtxQO7EvayJXAQkzFa7di&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1S2iGVK2hS-z6oyhTfo8r7lQivvADKnzA&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1HSD_VHDSXXiGiY3O4jRZKt9DB7vUfz3u&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1TQcR6icfy1GIF7KvpX1u9aQExuT3RXLQ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1XNGiQQ3_T5IiGM4svH-3Au1Yyv8AMBIm&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1c0uYL4e2IeJ0CO2hk0cylV3RWX-MOx8E&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wgQU93geTXYmZOQmlKBInvsTH2EYyPVZ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=10Hv_jEQxxpGDUClDX8kN7m3j0d1iUqNw&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1L-XblWEX5fazStYlXHxL7ZCW7erfx2tK&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1dhY4EwHF8cXIOh07r1DHtQXns5Se9L_a&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1y2qM0WzPWDC910sYMKaXNfRkIVsYaoDq&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1bqKLfveDXXt-16qRDrVLgFR5hMnFImWq&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1dbIgp_v8XTyYH-e44TZ8jRzdjfrtQInM&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1YRqg1bSr4eBGpE24PHZ_gsuBFj8JKnNp&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1IcvEXpJ-PgvAi5EUKUPiqXkW5n9V6-pt&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Y8QPE1eCJDV2woCiy_a8BDwdSJja3ZmY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1YdVMCjmJUz2VJpCZZdVQKCBIjmaxJXp9&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1xyw66dR8vN3cFnQbQUN2h0pLZzyJC-Xy&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1bTEhsMQQCgN_SZazudq0avgScGbHbf4f&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Yqk8SO4VaPc2XvlxtN7DMDxxMuYKhfYi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1zXjwsXkG2DsTAV9ij6ZLjwgv9cMlsrx1&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=19ARIsfYckbTEfNao103cB04xNUPmi0up&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wd9wBH-9sSaxqzG3AkkT_squNbSFoR9R&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=13_6OjnjNlR77S7pl485AbXLB0sS1C5-h&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1MhYBWsMrqXLqxj-F3r66FDm_s2U5FBVz&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1TA-H0SnsVl5rRpDqZk9yoxLgCtVrn0Vc&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1RMJsYFf76g7DkLoSr9_G5RQs4l1wiF1w&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wuw6AbF35RKg-NrNZi66Ar9Wkk8G7-Gi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1LMvK5yH3b9PpSBBaPGpe-i6Oo3YUL_EQ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1VST2FKxeTEep83y1GVpqiX37YHg27vvR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1rqM06MAD74H-FkLYw4ms2H5jYcANq787&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1LZIglVUEUuR_7eXgWqFr01NJdtqGcf1S&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1vsIVPWMqVwkEptIBwicUUmEaP20Czj1C&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1PAODEyEyTVXaOzVN6P2Vqm-nb8Sy40OY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Z3Y1ABe4sQBcVxDCFlutvBbWEFQdR17E&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1BKJvuvzkSDh_t_g34KlJ2p8dgc0L5w14&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1yUEQJuHOIVMCPrg--3Os_OgZS6s0960J&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=15saH3c5a7QRF-NK5Pw7_U3vBemO_gEWe&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1KWyf23ufleVjV7vM8xKIzuNZgFSAO3Nd&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=10UBtUgaBoL7ZJbqMN85o6iu9mSwrj5J-&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1iPoAF4JebAZGm_SjoTvER0e378RSpjX4&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=19FfG_vWQqIarCwYYXiTPj8aQ3bpVu2Be&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Zb5u9MxoUM8uj5-QKa7TXE20qSZQBzFe&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1-P4QIC04W3-f5Nrwh-sSSWfIx6sKOBHp&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1MHxGH5-U2CqRi5VXonpShBW68c2XDnve&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1lZtiBZXBj9MyB8FaHiHz2koRSlsuQvg6&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1-EkP1u6b9vF5xgYz8PYx5_Kk5gC7vpfY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1qP3mbGdKD2FMAL2RaIYxHBsL3YFuaWB_&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1BTSQm5XO3Q_asolK7XaNIVI3rRummvVG&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1RsfK883aM73BIgkBSpcYli_C4PdviOWJ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1LgkaviA6vQKt6-EiTyCziFFLRLaEkrCH&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1cCuF2fbJn0I3TvWCCP7kili_friO9xGk&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=12Vxmr15ApBb0uKKJBwyOD5Na7ixtBkQR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1DMMcxZks2V59fsJ1y0JjNhZ8Ft7wmF86&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ItEtEf9soeKSsEwlqnvzGArj_1gvndID&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1iMs3aSLeUXrPMgLJiqQfJqbwhIAD665Z&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1htNl6mmxygatMxJwxdbTHBUSwS8EMlgk&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=19xY7wPFVX_UYKHjldmj5IxpfRhB_pOWh&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1rIKCIVFiKbuFiPBufrA98_bbkL-S496D&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1N0KqeyEHDSVnM5wujcujhbIWxQIn3ZP4&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1-lXILlUciFUVEI5afWm9SlX_JCDv_Cie&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1kGc1yL4wDrNJNeO6d1s5lJHhshtC6FyK&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=11rd64BNCLOBkbZ8anPTpqGB0zeAun0kY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1N_gK59PhnVcMdV6NVc-pmigWjcsP7baH&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1hzYAYrSo1k3ygcCBl_-daqtFG_nENP6e&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1BMEe250ZM5RgE3TT1x7SQDhGMp-NpIms&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1OpSahKft7t1B8YM5RztuHGVsWHyh7nNW&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Dq9Xarueiw8RoJ1QyKpQ63asCX976dud&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=15Jg2BXCEqtQgarNZKDLYtuyFifEGqlIO&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Dhc6y5Cs0ZCdU5MIw8ioAAxo6R6iubod&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1YeNsHCd6KVLu5AXLqkdsltBdDN5XIccz&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1fV2EkYaytNxMMt6yTm34NQxxMOxbi6hR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1p2NH61g0r4vN6JpN1wzGXoaLeVj7kfjh&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ZGmwgtATVYxIxaIsky454O-w0CQEDPdz&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1g2AsSyqwdBku-O_3KSAO2bBNtZqDY3Vp&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wzH9KRhrMzAa2zUHWB7aTMfrcNcZAe_w&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1-5Ouzx6yXxmNx9cjtjVujCzA4rQPf4dj&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1bXsPvVCV945TJf7ZwF5U_T2TQMpiCEZD&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1hiA25jl59wMotaW5YJODoEgw_A196knR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1p1mAM_uXw5OxbbhwJB9TJo-vOxsHXkV3&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=158LL61OzWT_XGjQosZHPDLw71iY6rco3&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1JAE84W9swxQUU-Ta-n6HQjsrTNGnRN10&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1oMTQnGflJy9_boFVuwAHlAdNQdtUFUUY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1uOyfeeaH8rVNbZx2Rnc_HFQysB92eVSq&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1c5FeeczH7tFEYE2i7eXOg2JtIYlKwSLz&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1iNoY8VMwLg1nPoAoOSZ4yezj3CXSkXMu&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Ane0biXfPnJ3mlR05NgL4rV9TolOHrX-&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Y-BJ0cY1Wy7eHqzge6jmL1ctIzHWQ2mR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1SXHXz4Yhu_q_z0AQ9YPnsdRf9TZcuagd&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1z4BUtHpZ4xKPvFAGKM0q0J-TOKrKx2lS&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1AiBcOEuNlDhCUFrY3kKpaIFcmtR-EBpx&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1wm3cXMYqhRY0oRZ0LYhP1d1IDjQq7DWw&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=16p_2jcc3LDzvHURWiL9iHTAEGkfZDSWL&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ClkXckv-jeVBcFsvORe7bm66HWhyGbp1&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Np9sNCLgdKDQ93b5eJ8SUJMTERBh5QL2&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1k27mHB-L6WeV-wMmeLs_dKakTpF2u6lp&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Qybg2-s_AvIC0TYnw5ds1OL9V9l4pKMU&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1TRf1QU37wji5pAmr8b60R8jEikebj6dO&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1UkQAmjU5d4Z03PfmwQPKQqbmNrF5Q9OS&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1GCAQ-eFi9mRNhYweF5A-NbnuZgQFSDwa&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1iTFqvnCTLnOolXecQiaquRqJxgonrocb&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1N2NK-lklbLUEs7YsBVo5Ts_BCkNpMOnM&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1qUZSh_Z2PUAM4yHv2FfuHkfvEUnsRoa_&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1oiF44D-RSnpewU58RRZuhaNrzqMsUTTT&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1d6hPMFObyKUmp8eYPrU_G5-vlb8GJ3_j&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1WPqxh5R52V4IdGVKE4UBehui621C9Rkc&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=17j76oBMOoS2ue67bWBaSwGBR5nbo7dAy&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1SYKfEU9nq7CrAUn5aWumgVbgcDp7LGSi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ZCWeXAl1S1dJONN2IO6v2CqtY5ap-pMa&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1cbwuImkoZLvsWrWJzwR_N_FxGmCvo2mK&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=10irO_SH3DcIPJP382_hS9Y-8FItSsUKa&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ZklfdQVCcALfCj63PhboeHHkcP-B5aiU&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1yA20xVTzOcfazQiWM8CUPlKVMFzvGRGA&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1RNHifmrBVXrnspXybPE7l_-KAOZDO0fO&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1bdNwwXtVNqjN5YXo28CuxJvAyEeaxG8X&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1O2OUxFmxOf7TP6lb5xIczhbk_2IR3UST&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=13vUUU3qKNCgyAw46LbVJ-yc5PaoKmLIZ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1JfSOpuwS_Q5iUJd6VyKQALJwKU-PphhR&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1dESQ0piUNzgmuaysnTFgp8A_QBzTsXFB&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1nixT9giFL2GPPn6kjKvE_4dhFEGJtZDl&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1KjC5XtwgGIJony6XlPch4e4Ut7JVv4CE&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1glsMsMUrl6CgHz_qo5tWbnrfXJOvpBB2&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1kYq3ymjiHg4x_bZvZ5XDbS5ufWoKpOTi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1QXKa_x7Q4BRozljC-WxvJ7PXNiwaw3Dx&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1lVCW5tzOifnpF_Tn4h2uV_qJIew_U-i1&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1C5HdSZ7YNFzrtumtBZuARyN5t4c-KF-c&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1WV90o9PzNKoR8116CKhujX5J2nFn-RSh&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1tfMceASBPckCc1cJr-ZsDB2YthQ08luq&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=19tEU6s7cxPmqaLi6qCExDfve8KeoNl9h&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ZtFeiVBLuNHdJd0_IIxioAXJcjKpzv7b&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=12r2y8ta4BA9yKhY2dMKazzrAsplLHne6&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1nsRRBMyVunI6KWxDJr209avFyD5ll-I0&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1LimPQ7TPSnLyXjkmahF7b9KfrBfQEm9F&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Cszz4fCD77hDYFjHNVM_WZyPgKOGIeUY&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1kJIfa5mv1T4k6-Mp4J7U1SGqSV-JujkI&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1d6Mc3Af9RUPmSddlGbc5ce7nSEsUmdys&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1oG33p_C-9XxWO4BmuRQKFH_o3I0GVn9G&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1vbU1t3OAQrVXeMku5dQKsOS7X8GUeZse&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1x-xIVXjQrb6X017Te7vQfRxystnxqM-G&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1T28VHHj9b3kKe1mF29fLfLRVHxHCJuAh&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1t4touLGfsBkPPACo5s1E94HiB1mkyzJb&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=17IO_Or8eEnK8d8JJB-KrjpBtM2NDMxYN&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ETxLODTGMoTkn4121ZkecoTdfoCdl1SS&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1lNKMpm3pfP-Eh4guICSCe6JiEO6aqyVv&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1DlIBnUT7xDApBS0LgalPGZpsz0g0IKjP&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Y2KAUwEC6csgI6n4zejNvcjyY1Kmualt&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1be82xLiVuTwM3a-g4a-wNiYXf3yeXcKF&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1C6vaTFUDl2oTXrONBESyt2JmrIQUgJvi&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1KEc7_BzN08-jW7Gh0VdWcXGqnu8qAz9V&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1TaV2pBz1cnsLNAZsayYFbHH2WgaF_64K&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1uUNuBXMGaHiYBdjq6zfQ21Lbtm1wqWcp&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1DbNorvyhD6vbWDMXV-8EZatuCtjmurvg&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1peWoimjWn71_XqasKHl9vgXXTyKCtTsm&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=14I7lTsUGtm6m-B1nKNbuzfX3AoBlWFUy&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1074jYTSNUx44zHbsrAr7m7xfmJU80DOt&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1HcM_MsN8Fal9D_3DmivyNwIhErFykwdg&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1XniM0tHpIivm7bVHYEfhV0gG1MNlBKM_&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1mWPl9kgGD7kE3c_9Q998QvS8tHR11dJd&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1asRaTloSY52d2bBnatJvPLhdYW-B7PAT&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=109cLXLFcgGYh45P3c1KEwqH9uT2XXKwW&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Q3zO6F9IQdWe5PVXzsdI5dGzzigl9-pS&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Zqv3goZVf-YQtCc-4rDFr85lVEbJbyMU&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1te7EhZ6iYfOdxo49uhAlC9zi9w_PkBHy&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1tFIgsu4TiEBrXh6Ci5eSOaIO8Ue0MwGv&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1QGxAoxLRxSy1S9QrVekWYmRc0QrbIlVD&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1S__hfBh-lRThBZlHlwD61fETdg_KgYe1&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1YpedrlZykqhIT-De7rvqNRyAy1MytTfZ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1g2zxAJL-o4UckiM67wocSfvMxPg_wI1i&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1hezHwBkFhaoX1k5UIjDLlGXUfcbmEyuN&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1kb5iKSajbGlRt1Eaox-cIZ-k8X8QxtJe&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1u_CsFvj1L6OP6GPwi8VmjIJRgz1sLQVJ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1Ry77qJPzb_1S9hjMFUb1UCq7j4rm4HyU&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ISZ70tOvGX29DCRDBkNgt12kgUKU0Jlf&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1ZqRxZUzks-DI9RUL4eC9lYonyRtfdfnQ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=1vALUhhEj-8HtQDU4lH3qsq8A5KmE8IrJ&sz=w1000-h1000",
  "https://drive.google.com/thumbnail?id=17MLR-dUIKirarwO3MKEgzf1iIOwEnEXy&sz=w1000-h1000",
];
