import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";

import "./Navigation.scss";

import TalkToYourDad from "../TalkToYourDad/TalkToYourDad";
import Leaderboard from "../Leaderboard/Leaderboard";
import Home2 from "../Home2/Home2";
// import TheDocket from "../TheDocket/TheDocket";
import IsYourDadHome from "../IsYourDadHome/IsYourDadHome";
import Profile from "../Profile/Profile";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { navbarAllowMusic: true };
  }

  toggleAllowMusic() {
    this.setState((state) => ({
      navbarAllowMusic: !state.navbarAllowMusic,
    }));
  }

  auth(isAuthenticated, loginWithRedirect, logout, user) {
    if (isAuthenticated) {
      return (
        <Link to="/profile" className="m-0 py-0 pr-2">
          <img src={user.picture} height="40px" alt="profile" />
        </Link>
      );
    } else {
      return (
        <p className="fake-link my-auto" onClick={() => loginWithRedirect({})}>
          Log in
        </p>
      );
    }
  }

  render() {
    const { isAuthenticated, loginWithRedirect, logout, history, user } = this.props.auth0;
    const commonProps = { playMusic: this.props.appAllowMusic && this.state.navbarAllowMusic, auth0: this.props.auth0, userData: this.props.userData };
    return (
      <div id="navigation">
        <Router history={history}>
          <Navbar expand="lg" bg="light" variant="light">
            <Navbar.Brand>
              <p className="fake-link my-auto" onClick={() => this.toggleAllowMusic()}>
                I Am My Own Dad
              </p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Link to="/" className="my-auto">
                  Home
                </Link>
                <Link to="/talk-to-your-dad" className="my-auto">
                  Talk To Your Dad
                </Link>
                <Link to="/leaderboard" className="my-auto">
                  Leaderboard
                </Link>
                {/* <Link to="/the-docket" className="my-auto">
                  The Docket
                </Link> */}
                <Link to="/is-your-dad-home" className="my-auto">
                  Is Your Dad Home
                </Link>
              </Nav>
              <Nav>{this.auth(isAuthenticated, loginWithRedirect, logout, user)}</Nav>
            </Navbar.Collapse>
          </Navbar>
          <Switch>
            <Route path="/talk-to-your-dad">
              <TalkToYourDad {...commonProps} />
            </Route>
            <Route path="/leaderboard">
              <Leaderboard {...commonProps} />
            </Route>
            {/* <PrivateRoute path="/the-docket" component={TheDocket} {...commonProps}></PrivateRoute> */}
            {/* <Route path="/the-docket">
              <TheDocket {...commonProps} />
            </Route> */}
            <Route path="/is-your-dad-home">
              <IsYourDadHome {...commonProps} />
            </Route>
            <PrivateRoute path="/profile" component={Profile} {...commonProps}></PrivateRoute>
            <Route path="/">
              <Home2 {...commonProps} />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Navigation;
