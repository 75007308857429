import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Sound from "react-sound";

import "./IsYourDadHome.css";

import cottonEyeJoe from "../assets/songs/cotton-eye-joe.mp3";
// import doorbell from "../assets/sounds/doorbell.mp3";

import imageJerod from "../assets/images/isYourDadHome/Jerod Ray.jpg";
import imageTravis from "../assets/images/isYourDadHome/Travis King.JPG";
import imageBackup from "../assets/images/isYourDadHome/Bad Man.jpg";
const imageArray = [
  { name: "Jerod Ray", image: imageJerod },
  { name: "Travis King", image: imageTravis },
];

class IsYourDadHome extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     playDoorbell: false
  //   };
  // }

  // _subscribeToNewLinks = subscribeToMore => {
  //   subscribeToMore({
  //     document: SUBSCRIPTION,
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev
  //       const update = subscriptionData.data.HomeStatus.node;
  //       prev.allHomeStatuses.forEach(s => {
  //         if (s.id === update.id) {
  //           s.home = update.home;
  //           if (update.home) {
  //             this.setState({playDoorbell: true});
  //           }
  //         }
  //       })
  //       return prev;
  //     }
  //   })
  // }

  render() {
    return (
      <div id="isYourDadHome" className="container">
        {/* {this.state.playDoorbell &&
        <Sound url={doorbell} playStatus={Sound.status.PLAYING} onFinishedPlaying={() => {this.setState({playDoorbell: false}); console.log("end")}}/>
        } */}
        {this.props.playMusic && <Sound url={cottonEyeJoe} playStatus={Sound.status.PLAYING} />}
        <div className="row">
          <div className="col">
            <h1 className="centered">Is your Dad Home?</h1>
          </div>
        </div>
        <Query query={QUERY}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className="fetching">Fetching from the Daddybase(TM)</p>
                    </div>
                  </div>
                </div>
              );
            if (error) {
              console.log("Error:", error);
              return <div>Error</div>;
            }
            // this._subscribeToNewLinks(subscribeToMore)

            const { homeStatuses } = data;
            const dads = homeStatuses.map((dad) => {
              var thisImage;
              imageArray.forEach((a) => {
                if (a.name === dad.name) {
                  thisImage = a.image;
                }
              });
              if (!thisImage) {
                thisImage = imageBackup;
              }
              return (
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6" key={dad.name}>
                  <div className="team-member">
                    <img src={thisImage} className={`${dad.home ? "home" : "away"} mx-auto rounded-circle img-fluid custom-image`} alt={dad.name} />
                    <p className="text-muted centered">{dad.name}</p>
                  </div>
                </div>
              );
            });
            return <div className="row justify-content-center">{dads}</div>;
          }}
        </Query>
      </div>
    );
  }
}

export default IsYourDadHome;

const QUERY = gql`
  query homeStatuses {
    homeStatuses {
      id
      name
      home
    }
  }
`;
// const SUBSCRIPTION = gql`
//   subscription newStatus {
//     HomeStatus(
//       filter: {
//         mutation_in: [UPDATED],
//       }
//     ) {
//       mutation
//       node {
//         id,
//         name,
//         home
//       }
//     }
//   }
// `
