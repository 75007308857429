import React from "react";

import "./Profile.scss";

class Profile extends React.Component {
  render() {
    console.log(this.props);
    const { auth0 } = this.props;
    const { email, username, firstName, lastName, originalCreatedAt } = this.props.userData;
    return (
      <div id="profile">
        {/* <Query query={QUERY} variables={{ auth0id: auth0.user.sub }}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className="fetching">Fetching from the Daddybase(TM)</p>
                    </div>
                  </div>
                </div>
              );
            if (error) {
              console.log("Error:", error);
              return <div>Error</div>;
            } */}
        <div className="container">
          <div className="row mt-3 mb-2">
            <div className="col-auto mx-auto">
              <h1>Your Profile</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md text-center">
              <p className="label">Name</p>
              <p className="field">{firstName + " " + lastName}</p>
            </div>
            <div className="col-12 col-sm-6 col-md text-center">
              <p className="label">Username</p>
              <p className="field">{username}</p>
            </div>
            <div className="col-12 col-sm-6 col-md text-center">
              <p className="label">Email</p>
              <p className="field">{email}</p>
            </div>
            <div className="col-12 col-sm-6 col-md text-center">
              <p className="label">User Since</p>
              <p className="field">{new Date(originalCreatedAt).toLocaleDateString("US")}</p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button className="btn btn-primary logout mb-3" onClick={() => auth0.logout()}>
                Logout
              </button>
            </div>
          </div>
        </div>
        );
        {/* }}
        </Query> */}
      </div>
    );
  }
}

export default Profile;
